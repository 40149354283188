import { useAppDispatch, useAppSelector } from "../store/hooks";
import { useEffect, useState } from "react";
import {
  addMultiPage,
  nextPage,
  prevPage,
  removeMultiPage,
  selectConditionals,
  selectCurrentPageNumber,
  selectData,
  selectDrawableForm,
  selectFilledForm,
  selectLoading,
  selectMultifields,
  selectNumPages,
  setCode,
  tryGetByCode,
  tryUpdate,
  updateValue,
  selectIsDone,
  tryUploadFile,
  selectOptionsLists,
  selectValidations,
  updateParcial,
  selectErrorMessage,
  resetErrorMessage,
  tryPause,
  selectPauseState,
  changePauseState,
  selectPauseRedirect,
  resetGenPath,
  selectLastButton,
  selectUploadError,
  setErrorAck,
  selectToken,
  selectDarkMode,
  tryDeleteFile,
} from "./formComponentSlicer";
import { getColorFromCode, getRedirectionUrl } from "../api/magic_forms_2_api";
import useQuery from "../../utils/UseQuery";
import { ClipLoader } from "react-spinners";
import FormField from "../dtos/FormField";
import { GenericField2 } from "./GenericField2";
import { ButtonStyle, ButtonStyleNoHeight } from "../../utils/Buttons/Buttons";
import { infoMessage } from "../../api/errorHandler";
import OptionsList from "../dtos/OptionsList";
import { PopUpElement } from "../agentComponents/PopUpElement";
import {
  PauseComponent,
  ConfirmationComponent,
} from "../agentComponents/PauseComponent";
import { ReactComponent as Warning } from "../../assets/warning.svg";
import { ReactComponent as Info } from "../../assets/info.svg";

const Form = () => {
  //query params
  const query = useQuery();
  const code = query.get("code") ?? "error";
  //State selectors
  let currentPageNumber = useAppSelector(selectCurrentPageNumber);
  let filledForm = useAppSelector(selectFilledForm);
  let numPages = useAppSelector(selectNumPages);
  let drawableTemplate = useAppSelector(selectDrawableForm);
  let data = useAppSelector(selectData);
  let conds = useAppSelector(selectConditionals);
  let loading = useAppSelector(selectLoading);
  let multiFields = useAppSelector(selectMultifields);
  let isDone = useAppSelector(selectIsDone);

  let optionsLists = useAppSelector(selectOptionsLists);
  let validations = useAppSelector(selectValidations);
  let errorMessage = useAppSelector(selectErrorMessage);
  let pauseState = useAppSelector(selectPauseState);
  let pauseRedirect = useAppSelector(selectPauseRedirect);
  let uploadError = useAppSelector(selectUploadError);
  let token = useAppSelector(selectToken);
  //Dispatch
  let dispatch = useAppDispatch();

  //local states
  const [bgColor, setBgColor] = useState(["#ffffff", "#ffffff"]);
  const [logo, setLogo] = useState("_");
  const [darkMode2, setDarkMode] = useState(true);
  const [menuStatus, setMenuStatus] = useState<
    "Static" | "Extended" | "Deployed"
  >("Static");

  const [showConfirmation, setShowConfirmation] = useState(false);

  const [stepPause, setStepPause] = useState(0);

  const changeMenuStatus = (status: "Static" | "Extended" | "Deployed") => {
    setMenuStatus(status);
    if (status === "Static") {
      setShowConfirmation(false);
    }
  };

  const customStyle = {
    "--textColor": darkMode2 ? "#ffffff" : "#000000",
    "--inverted": darkMode2 ? "invert(0)" : "invert(1)",
  } as React.CSSProperties;

  //get colors
  async function getColors() {
    let resp = await getColorFromCode(code);
    setBgColor([resp.backgroundColor1, resp.backgroundColor2]);
    setLogo(resp.logoUrl);
    setDarkMode(resp.darkMode);
    // setDarkMode(true)
  }

  const lifeSaverPause = () => {
    setShowConfirmation(true);
  };

  const pauseFunctionYes = () => {
    setMenuStatus("Static");
    setShowConfirmation(false);
    dispatch(tryPause(code));
  };

  const pauseFunctionNo = () => {
    setMenuStatus("Static");
    setShowConfirmation(false);
  };

  const errorPopup = () => {
    if (errorMessage) {
      switch (errorMessage) {
        case "Invalid Token":
          return (
            <PopUpElement
              content={
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "30px",
                    marginTop: "20px",
                  }}
                >
                  <h5
                    style={{
                      textAlign: "start",
                      fontSize: "24px",
                      fontFamily: "Open Sans",
                      fontWeight: "bold",
                      color: darkMode2 ? "#000000" : "#ffffff",
                      margin: "0",
                    }}
                  >
                    Advertencia
                  </h5>
                  <Warning
                    style={{
                      width: "70px",
                      height: "70px",
                      margin: "10px 0 10px 0",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: "initial",
                      fontSize: "18px",
                      color: darkMode2 ? "#000000" : "#ffffff",
                      textAlign: "start",
                    }}
                  >
                    El formulario no es válido, revisa el enlace o intenta de
                    nuevo
                  </p>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(resetErrorMessage());
                    }}
                    style={{
                      padding: "14px 44px 14px 44px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "20px",
                      fontWeight: "normal",
                      fontFamily: "'Open Sans'",
                      color: "white",
                      background: "rgba(0,0,0)",
                      borderRadius: "30px",
                      border: "none",
                      textTransform: "uppercase",
                      cursor: "pointer",
                    }}
                  >
                    Aceptar
                  </button>
                </div>
              }
            />
          );
        case "Invalid Credentials":
          return (
            <PopUpElement
              content={
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "30px",
                    marginTop: "20px",
                  }}
                >
                  <h5
                    style={{
                      textAlign: "start",
                      fontSize: "24px",
                      fontFamily: "Open Sans",
                      fontWeight: "bold",
                      color: "#000000",
                      margin: "0",
                    }}
                  >
                    Advertencia
                  </h5>
                  <Warning
                    style={{
                      width: "70px",
                      height: "70px",
                      margin: "10px 0 10px 0",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: "initial",
                      fontSize: "18px",
                      color: "#000000",
                      textAlign: "start",
                    }}
                  >
                    El formulario no es válido, revisa el enlace o intenta de
                    nuevo
                  </p>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(resetErrorMessage());
                    }}
                    style={{
                      padding: "14px 44px 14px 44px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "20px",
                      fontWeight: "normal",
                      fontFamily: "'Open Sans'",
                      color: "white",
                      background: "rgba(0,0,0)",
                      borderRadius: "30px",
                      border: "none",
                      textTransform: "uppercase",
                      cursor: "pointer",
                    }}
                  >
                    Aceptar
                  </button>
                </div>
              }
            />
          );
        case "Invalid Code":
          return (
            <PopUpElement
              content={
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "30px",
                    marginTop: "20px",
                  }}
                >
                  <h5
                    style={{
                      textAlign: "start",
                      fontSize: "24px",
                      fontFamily: "Open Sans",
                      fontWeight: "bold",
                      color: "#000000",
                      margin: "0",
                    }}
                  >
                    Advertencia
                  </h5>
                  <Warning
                    style={{
                      width: "70px",
                      height: "70px",
                      margin: "10px 0 10px 0",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: "initial",
                      fontSize: "18px",
                      color: "#000000",
                      textAlign: "start",
                    }}
                  >
                    El código no es válido
                  </p>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(resetErrorMessage());
                    }}
                    style={{
                      padding: "14px 44px 14px 44px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "20px",
                      fontWeight: "normal",
                      fontFamily: "'Open Sans'",
                      color: "white",
                      background: "rgba(0,0,0)",
                      borderRadius: "30px",
                      border: "none",
                      textTransform: "uppercase",
                      cursor: "pointer",
                    }}
                  >
                    Aceptar
                  </button>
                </div>
              }
            />
          );
        case "Form Done":
          return (
            <PopUpElement
              content={
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "30px",
                    marginTop: "20px",
                  }}
                >
                  <h5
                    style={{
                      textAlign: "start",
                      fontSize: "24px",
                      fontFamily: "Open Sans",
                      fontWeight: "bold",
                      color: "#000000",
                      margin: "0",
                    }}
                  >
                    Advertencia
                  </h5>
                  <Warning
                    style={{
                      width: "70px",
                      height: "70px",
                      margin: "10px 0 10px 0",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: "initial",
                      fontSize: "18px",
                      color: "#000000",
                      textAlign: "start",
                    }}
                  >
                    El formulario ya ha sido completado
                  </p>
                  <button
                    onClick={async (e) => {
                      e.preventDefault();
                      let resp = await getRedirectionUrl(token);
                      let redirectionUrl = "https://anteia.co";
                      if (resp.success) {
                        redirectionUrl = resp.url!;
                      }
                      window.location.href =
                        redirectionUrl || "https://anteia.co";
                    }}
                    style={{
                      padding: "14px 44px 14px 44px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "20px",
                      fontWeight: "normal",
                      fontFamily: "'Open Sans'",
                      color: "white",
                      background: "rgba(0,0,0)",
                      borderRadius: "30px",
                      border: "none",
                      textTransform: "uppercase",
                      cursor: "pointer",
                    }}
                  >
                    Continuar
                  </button>
                </div>
              }
            />
          );
        case "Expired":
          return (
            <PopUpElement
              content={
                stepPause === 0 ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: "30px",
                      marginTop: "20px",
                    }}
                  >
                    <h5
                      style={{
                        textAlign: "start",
                        fontSize: "24px",
                        fontFamily: "Open Sans",
                        fontWeight: "bold",
                        color: "#000000",
                        margin: "0",
                      }}
                    >
                      Información
                    </h5>
                    <Info
                      style={{
                        width: "70px",
                        height: "70px",
                        margin: "10px 0 10px 0",
                      }}
                    />
                    <p
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "initial",
                        fontSize: "18px",
                        color: "#000000",
                        textAlign: "start",
                      }}
                    >
                      El código ha expirado, sin embargo, si deseas continuar
                      con el formulario, dale clic a aceptar, y te enviaremos un
                      correo electrónico, con el cual puedes continuar el
                      formulario.
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(tryPause(code));
                        setStepPause(1);
                      }}
                      style={{
                        padding: "14px 44px 14px 44px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        fontWeight: "normal",
                        fontFamily: "'Open Sans'",
                        color: "white",
                        background: "rgba(0,0,0)",
                        borderRadius: "30px",
                        border: "none",
                        textTransform: "uppercase",
                        cursor: "pointer",
                      }}
                    >
                      Aceptar
                    </button>
                  </div>
                ) : (
                  <div></div>
                )
              }
            />
          );
        default:
          return <div></div>;
      }
    } else {
      return <div></div>;
    }
  };

  //Upload Error popup
  useEffect(() => {
    console.log("Upload Error Use Effect");
    console.log(uploadError);
    if (uploadError) {
      uploadErrorPopup();
    }
  }, [uploadError]);

  const uploadErrorPopup = () => {
    if (uploadError) {
      return (
        <PopUpElement
          content={
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "30px",
                marginTop: "20px",
              }}
            >
              <h5
                style={{
                  textAlign: "start",
                  fontSize: "24px",
                  fontFamily: "Open Sans",
                  fontWeight: "bold",
                  color: "#000000",
                  margin: "0",
                }}
              >
                Advertencia
              </h5>
              <Warning
                style={{
                  width: "70px",
                  height: "70px",
                  margin: "10px 0 10px 0",
                }}
              />
              <p
                style={{
                  fontFamily: "Open Sans",
                  fontWeight: "initial",
                  fontSize: "18px",
                  color: "#000000",
                  textAlign: "start",
                }}
              >
                Ocurrió un error cargando el archivo, por favor intenta de
                nuevo!
              </p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setErrorAck());
                }}
                style={{
                  padding: "14px 44px 14px 44px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  fontWeight: "normal",
                  fontFamily: "'Open Sans'",
                  color: "white",
                  background: "rgba(0,0,0)",
                  borderRadius: "30px",
                  border: "none",
                  textTransform: "uppercase",
                  cursor: "pointer",
                }}
              >
                Aceptar
              </button>
            </div>
          }
        />
      );
    } else {
      return <div></div>;
    }
  };

  const showPopUp = () => {
    switch (pauseState) {
      case "None":
        return <div></div>;
      case "Paused":
        return (
          <PopUpElement
            content={
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  margin: "5px 0 25px 0",
                }}
              >
                <h5
                  style={{
                    textAlign: "start",
                    fontSize: "24px",
                    fontFamily: "Open Sans",
                    fontWeight: "bold",
                    color: "#000000",
                    margin: "0",
                  }}
                >
                  Proceso pausado
                </h5>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "26px 0px 8px 0px",
                  }}
                >
                  <h6
                    style={{
                      textAlign: "start",
                      fontSize: "20px",
                      fontFamily: "Open Sans",
                      fontWeight: "initial",
                      color: "#000000",
                      margin: "0 0 0 0",
                      width: "150px",
                    }}
                  >
                    Acabas de pausar el proceso
                  </h6>
                  <img
                    src='https://cdn.anteia.co/images/readingBook.png'
                    alt='Caricatura de persona leyendo un libro'
                    height={"120px"}
                    width={"120px"}
                  />
                </div>
                <p
                  style={{
                    fontFamily: "Open Sans",
                    fontWeight: "initial",
                    fontSize: "18px",
                    color: "#000000",
                    textAlign: "start",
                  }}
                >
                  Te enviaremos un mensaje a tu cuenta de correo electrónico en
                  el cual encontrarás un enlace para retomar este proceso en
                  cualquier momento.
                </p>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(changePauseState("Close"));
                  }}
                  style={{
                    padding: "14px 44px 14px 44px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    fontWeight: "normal",
                    fontFamily: "'Open Sans'",
                    color: "white",
                    background: "rgba(0,0,0)",
                    borderRadius: "30px",
                    border: "none",
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                >
                  Aceptar
                </button>
              </div>
            }
          />
        );
      case "Close":
        return (
          <PopUpElement
            content={
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  margin: "5px 0 25px 0",
                }}
              >
                <h5
                  style={{
                    textAlign: "start",
                    fontSize: "36px",
                    fontFamily: "Open Sans",
                    fontWeight: "bold",
                    color: "#000000",
                    width: "60%",
                    margin: "5% 0 5% 0",
                  }}
                >
                  Gracias por tu elección
                </h5>
                <p
                  style={{
                    textAlign: "start",
                    fontSize: "24px",
                    fontFamily: "Open Sans",
                    fontWeight: "bold",
                    color: "#000000",
                    width: "70%",
                    margin: "0",
                  }}
                >
                  Ya puedes cerrar esta ventana de tu navegador
                </p>
              </div>
            }
          />
        );
      default:
        return <div></div>;
    }
  };

  useEffect(() => {
    // if (!cookies.pauseAdvice) {
    //   let searchParams = `?code=${code}`;

    //   history.replace({ pathname: "explanation", search: searchParams });
    // }
    if (pauseRedirect && pauseRedirect !== "") {
      dispatch(resetGenPath());
    }
    if (isDone) {
      if (token) {
        getRedirectionUrl(token)
          .then((resp) => {
            if (resp.success) {
              let redUrl = resp.url;
              if (redUrl) {
                window.location.href = redUrl;
              }
            }
          })
          .catch((err) => {
            window.location.href = "https://anteia.co";
          });
      }
    }
  }, [isDone, currentPageNumber, token]);

  useEffect(() => {
    scrollTop();
  }, [currentPageNumber]);

  function checkPageFilled(): Array<string> {
    let lst = [];
    console.log("CheckPAgeFilledddddddd");
    let r = true;
    let page = drawableTemplate?.formPages[currentPageNumber];
    for (let k in page?.fields) {
      let field = page?.fields[k];
      if (
        field?.type !== "label" && //check not label
        field?.type !== "multi"
      ) {
        if (field?.optional === false) {
          if (field?.conditionalSourceId && conds[field?.conditionalSourceId]) {
            if (data[k] === "" || !data[k]) {
              console.log(k);
              lst.push(field?.title);
              r = false;
            }
            if (
              field?.type === "linkedList2" ||
              field?.type === "linkedList3"
            ) {
              let data1 = data[field?.mappedListSubId1 ?? "____"];
              if (data1 === "" || !data1) {
                console.log(field?.mappedListSubId1 ?? "____");
                lst.push(field?.mappedListSubTitle1 ?? "");
              }
              if (field?.type === "linkedList3") {
                let data2 = data[field?.mappedListSubId2 ?? "____"];
                if (data2 === "" || !data2) {
                  console.log(field?.mappedListSubId2 ?? "____");
                  lst.push(field?.mappedListSubTitle2 ?? "");
                }
              }
            }
          } else if (!field?.conditionalSourceId) {
            if (data[k] === "" || !data[k]) {
              console.log(k);
              lst.push(field?.title);
              r = false;
            }
            if (
              field?.type === "linkedList2" ||
              field?.type === "linkedList3"
            ) {
              let data1 = data[field?.mappedListSubId1 ?? "____"];
              if (data1 === "" || !data1) {
                console.log(field?.mappedListSubId1 ?? "____");
                lst.push(field?.mappedListSubTitle1 ?? "");
              }
              if (field?.type === "linkedList3") {
                let data2 = data[field?.mappedListSubId2 ?? "____"];
                if (data2 === "" || !data2) {
                  console.log(field?.mappedListSubId2 ?? "____");
                  lst.push(field?.mappedListSubTitle2 ?? "");
                }
              }
            }
          }
        }
      } else if (field?.type === "multi") {
        if (
          !field?.conditionalSourceId ||
          (field?.conditionalSourceId && conds[field?.conditionalSourceId])
        ) {
          let subFields = field?.subFields ?? {};
          for (let subKey in subFields) {
            let subField = field?.subFields[subKey];
            if (subField && subField.optional === false) {
              let size = multiFields[field.stitchTypeId];
              if (size && !Number.isNaN(size)) {
                for (let i = 0; i < size; i++) {
                  let subId = subField.stitchTypeId.replace(
                    field.stitchTypeId + ".",
                    ""
                  );
                  let wholeId = field.stitchTypeId + `[${i}].` + subId;
                  let value = data[wholeId];
                  if (!value || value === "") lst.push(subField.title);
                }
              }
            }
          }
        }
      }
    }
    console.log(lst);
    return lst;
  }

  function checkValidations(): Array<string> {
    let lst = [];
    console.log("CheckValidations");
    let r = true;
    let page = drawableTemplate?.formPages[currentPageNumber];
    for (let k in page?.fields) {
      let field = page?.fields[k];
      if (field?.type !== "label") {
        //Check Validations
        console.log("chk val");
        if (field?.conditionalSourceId && conds[field?.conditionalSourceId]) {
          if (validations[k] !== "" && validations[k]) {
            console.log(k);
            lst.push(field?.title);
            r = false;
          }
        } else if (!field?.conditionalSourceId) {
          if (validations[k] !== "" && validations[k]) {
            console.log(k);
            lst.push(field?.title ? field.title : "");
            r = false;
          }
        }
        //CheckMultiSubFields
        if (field && field?.type === "multi") {
          let subFields = field?.subFields ?? {};
          for (let subKey in subFields) {
            let subField = field?.subFields[subKey];
            if (subField && subField.validator) {
              let size = multiFields[field.stitchTypeId];
              if (size && !Number.isNaN(size)) {
                for (let i = 0; i < size; i++) {
                  let subId = subField.stitchTypeId.replace(
                    field.stitchTypeId + ".",
                    ""
                  );
                  let wholeId = field.stitchTypeId + `[${i}].` + subId;
                  let validatorMsg = validations[wholeId];
                  if (validatorMsg && validatorMsg !== "")
                    lst.push(subField.title);
                }
              }
            }
          }
        }
      }
    }
    console.log(lst);
    return lst;
  }

  function getFormAndStuff() {
    dispatch(tryGetByCode(code));
  }

  function dispatchUpdate() {
    dispatch(tryUpdate());
  }

  function setCodeLocal() {
    dispatch(setCode(code));
  }

  function addMultiPageDispatch(id: string) {
    dispatch(addMultiPage(id));
  }

  function removeMultiPageDispatch(id: string) {
    dispatch(removeMultiPage(id));
  }

  function scrollTop() {
    let elem = document.getElementById("scroller2");
    if (elem !== null) {
      elem.scrollTop = 0;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  function sendNextPage() {
    window.focus();
    window.scrollTo(0, 0);
    let lstEmpty = checkPageFilled();
    let lstVal = checkValidations();
    if (lstEmpty.length !== 0) {
      let msg = "Por favor llene los siguientes campos: ";
      let index = 0;
      lstEmpty.forEach((i) => {
        let separator = index < lstEmpty.length - 1 ? "," : "";
        msg += "\n" + i + separator;
        index++;
      });
      infoMessage(msg);
      return;
    } else if (lstVal.length !== 0) {
      let msg = "Algunos campos no contienen datos válidos";
      infoMessage(msg);
      return;
    } else {
      scrollTop();
      dispatch(nextPage());
    }
    if (currentPageNumber === numPages - 1) {
      dispatchUpdate();
      return;
    } else {
      dispatch(updateParcial());
    }
  }

  function uploadFile(file: File, fieldId: string) {
    let newName = file.name.replaceAll(" ", "_");
    dispatch(
      tryUploadFile({
        fieldId: fieldId,
        file: file,
        fileName: newName,
      })
    );
  }

  function deleteFile(fieldId: string) {
    dispatch(tryDeleteFile({ stitchTypeId: fieldId }));
  }

  function sendPreviousPage() {
    scrollTop();
    dispatch(prevPage());
    dispatch(updateParcial());
  }

  //Initialize Everything
  useEffect(() => {
    getColors();
    setCodeLocal();
    getFormAndStuff();
  }, []);

  function getLastButtons() {
    let lst: JSX.Element[] = [];
    if (drawableTemplate && drawableTemplate.customFinalButtons) {
      for (let label in drawableTemplate?.customFinalButtons?.valueMap) {
        let value = drawableTemplate?.customFinalButtons?.valueMap[label];
        lst.push(
          <div className='row' style={{ margin: "10px" }}>
            <ButtonStyle
              onClick={() => {
                console.log(
                  "label " +
                    label +
                    ",val " +
                    value +
                    ",id " +
                    drawableTemplate?.customFinalButtons?.stitchTypeId
                );
                dispatch(
                  selectLastButton({
                    fieldId:
                      drawableTemplate?.customFinalButtons?.stitchTypeId ??
                      "error",
                    data: value,
                  })
                ).then((val) => {
                  console.log("theeen");
                  sendNextPage();
                });
              }}
              text={label}
              width='177px'
              height='5vh'
            />
          </div>
        );
      }
    }
    return lst;
  }
  //Render
  return (
    <div style={customStyle}>
      <div
        style={{
          background: `radial-gradient(${bgColor[0]}, ${bgColor[1]})`,
          position: "fixed",
          height: "100%",
          width: "100%",
          overflow: "auto",
          paddingTop: "5%",
        }}
        id='scroller2'
      >
        <div
          className='col'
          style={{ paddingTop: "10%", marginLeft: "10vw", marginRight: "10vw" }}
          id='scroller'
        >
          {logo && logo !== "_" && currentPageNumber === 0 && (
            <div className='img-'>
              <img src={logo} className='img-logo' alt='LOGO'></img>
            </div>
          )}
          {!loading && (
            <h1
              className='txt-label-title'
              style={{ fontSize: "", color: darkMode2 ? "white" : "black" }}
            >
              {drawableTemplate?.formPages[currentPageNumber]?.title}
            </h1>
          )}

          <ClipLoader
            loading={loading}
            size={150}
            color={darkMode2 ? "#FFFFFF" : "#000000"}
          />

          {!loading && (
            <FieldList
              fields={
                drawableTemplate?.formPages[currentPageNumber].fields ?? {}
              }
              onChange={(val: string, fieldId: string) => {
                dispatch(updateValue({ fieldId: fieldId, data: val }));
              }}
              data={data}
              conds={conds}
              multiFields={multiFields}
              addMultiItem={addMultiPageDispatch}
              deleteMultiItem={removeMultiPageDispatch}
              uploadFileFunction={uploadFile}
              optionsLists={optionsLists}
              validations={validations}
              darkMode={darkMode2}
              customStyles={customStyle}
              deleteFileFunction={deleteFile}
            />
          )}
        </div>
        {!loading && (drawableTemplate || filledForm) && (
          <div style={{ marginTop: "5%", marginBottom: "30%" }}>
            <div className='row-12'>
              {currentPageNumber === numPages - 1 &&
                drawableTemplate?.customFinalButtons && (
                  <div className='row-12'>{getLastButtons()}</div>
                )}
              {currentPageNumber !== 0 && (
                <ButtonStyle
                  onClick={() => {
                    sendPreviousPage();
                  }}
                  text='Anterior'
                  width='177px'
                  height='5vh'
                />
              )}
              <div style={{ width: "10px", height: "30px" }} />

              {(currentPageNumber !== numPages - 1 ||
                !drawableTemplate?.customFinalButtons) && (
                <ButtonStyle
                  onClick={() => {
                    sendNextPage();
                  }}
                  text={
                    currentPageNumber === numPages - 1
                      ? drawableTemplate?.finalButtonText ?? "Terminar"
                      : "Siguiente"
                  }
                  width='177px'
                  height='5vh'
                />
              )}
            </div>
          </div>
        )}
        {showPopUp()}
        {errorPopup()}
        {uploadErrorPopup()}
        {showConfirmation && (
          <ConfirmationComponent
            noFunction={pauseFunctionNo}
            yesFunction={pauseFunctionYes}
          />
        )}
        {(drawableTemplate || filledForm) && (
          <PauseComponent
            menuStatus={menuStatus}
            setMenuStatus={changeMenuStatus}
            pauseFunction={lifeSaverPause}
          />
        )}
      </div>
    </div>
  );
};

export default Form;
interface FieldListProps {
  fields: { [key: string]: FormField };
  onChange: (val: string, fieldId: string) => void;
  data: { [key: string]: string };
  conds: { [key: string]: boolean };
  multiFields: { [key: string]: number };
  addMultiItem: (id: string) => void;
  deleteMultiItem: (id: string) => void;
  uploadFileFunction: (file: File, fieldId: string) => void;
  optionsLists: { [key: string]: OptionsList };
  validations: { [key: string]: string };
  darkMode: boolean;
  customStyles: React.CSSProperties;
  deleteFileFunction: (fieldId: string) => void;
}
const FieldList = (props: FieldListProps) => {
  //OnChangeBasic
  function ocBasic(ev: string, fieldId: string): void {
    let txt = ev;
    props.onChange(txt, fieldId);
  }

  //OnChangeFile
  function ocFile(
    ev: React.FormEvent<HTMLInputElement>,
    fieldId: string
  ): void {
    let txt = ev.currentTarget?.files?.item(0)?.name ?? "";
    txt = txt.replace(" ", "_");
    let block = false;
    let supTxt = "";
    if (props.fields[fieldId]?.supportedExtensions.length > 0) {
      block = true;
      console.log(props.fields[fieldId]?.supportedExtensions);
      props.fields[fieldId]?.supportedExtensions.forEach((ext) => {
        supTxt += ext + ", ";
        if (txt.endsWith(ext)) block = false;
      });
    }
    if (block) {
      supTxt = supTxt.slice(0, -2);
      infoMessage(
        "El archivo no tiene el formato adecuado, los formatos soportados son: " +
          supTxt
      );
    } else {
      props.onChange(txt, fieldId);
      props.uploadFileFunction(ev.currentTarget.files!.item(0)!, fieldId);
    }
  }

  //Oc for multiChoice and singleChoice
  function ocMulti(
    choice: string,
    checked: boolean,
    fieldId: string,
    index: number
  ): void {
    let orig = props.data[fieldId];
    if (choice.includes(":otro:")) {
      let sels = orig.split("#");
      sels.forEach((s) => {
        if (s.includes(":otro:")) {
          orig = orig.replace(`#${s}`, "");
        }
      });
      if (checked) {
        orig = orig + `#${choice}`;
      }
      props.onChange(orig, fieldId);
    }
    //Else
    else if (checked) {
      props.onChange(orig + "#" + choice, fieldId);
      props.onChange("true", `${fieldId}.${index}`);
    } else {
      props.onChange(orig.replace("#" + choice, ""), fieldId);
      props.onChange("false", `${fieldId}.${index}`);
    }
  }

  function ocSingleChoice(choice: string, fieldId: string): void {
    props.onChange(choice, fieldId);
  }

  function getRows(): JSX.Element[] {
    let r: JSX.Element[] = [];
    for (let k in props.fields) {
      let field = props.fields[k];
      let deleteFile = (): void => {
        props.deleteFileFunction(field.stitchTypeId);
      };
      let validation = props.validations[field.stitchTypeId]
        ? props.validations[field.stitchTypeId]
        : "";
      let choices = field.choices;
      if (!choices) choices = [];
      if (
        field.type === "dropdown_dynamic" ||
        field.type === "linkedList2" ||
        field.type === "linkedList3"
      ) {
        choices = props.optionsLists[field.stitchTypeId].options;
      }
      if (props.conds[field.conditionalSourceId ?? ""] !== false) {
        //SecondClassListMap....
        if (field.type === "linkedList2") {
          let oc: any = (ev: string) => {
            ocBasic(ev, field.stitchTypeId);
          };
          let ocSub1: any = (ev: string) => {
            ocBasic(ev, field.mappedListSubId1 ?? "errorData");
          };
          let showSecondList = false;
          let secondList = <div></div>;
          let dataForSub = props.data[field.stitchTypeId];
          if (dataForSub) {
            let listMap = props.optionsLists[field.stitchTypeId].class2ListMap;

            if (listMap) {
              let list = listMap[dataForSub];
              showSecondList = true;
              secondList = (
                <div key={field.order + ":" + field.stitchTypeId + "1"}>
                  <GenericField2
                    value={props.data[field.mappedListSubId1 ?? ""]}
                    onChange={ocSub1}
                    title={field.mappedListSubTitle1}
                    fieldType={"dropdown_dynamic"}
                    description={field.description}
                    options={list}
                    supportsOther={false}
                    disabled={false}
                    darkMode={props.darkMode}
                    deleteFileFunction={deleteFile}
                  />
                </div>
              );
            }
          }
          let firstList = [];
          for (let valueText in props.optionsLists[field.stitchTypeId]
            .class2ListMap) {
            firstList.push(valueText);
          }
          let f = (
            <div key={field.order + ":" + field.stitchTypeId}>
              <GenericField2
                value={props.data[field.stitchTypeId]}
                onChange={oc}
                title={field.title}
                fieldType={"dropdown_dynamic"}
                description={field.description}
                options={firstList}
                supportsOther={field.supportsOther}
                disabled={!field.userEditable}
                darkMode={props.darkMode}
                deleteFileFunction={deleteFile}
              />
            </div>
          );
          r.push(f);
          if (showSecondList) r.push(secondList);
        } else if (field.type === "linkedList3") {
          let oc: any = (ev: string) => {
            ocBasic(ev, field.stitchTypeId);
          };
          let ocSub1: any = (ev: string) => {
            ocBasic(ev, field.mappedListSubId1 ?? "errorData");
          };
          let ocSub2: any = (ev: string) => {
            ocBasic(ev, field.mappedListSubId2 ?? "errorData");
          };
          let showSecondList = false;
          let secondList = <div></div>;
          let showThirdList = false;
          let thirdList = <div></div>;
          let dataForSub = props.data[field.stitchTypeId];
          if (dataForSub) {
            let listMap = props.optionsLists[field.stitchTypeId].class3ListMap;
            if (listMap) {
              let list = listMap[dataForSub];
              let listValues1 = [];
              for (let listItem in list) {
                listValues1.push(listItem);
              }
              showSecondList = true;
              secondList = (
                <div key={field.order + ":" + field.stitchTypeId + "1"}>
                  <GenericField2
                    value={props.data[field.mappedListSubId1 ?? ""]}
                    onChange={ocSub1}
                    title={field.mappedListSubTitle1}
                    fieldType={"dropdown_dynamic"}
                    description={field.description}
                    options={listValues1}
                    supportsOther={false}
                    disabled={false}
                    darkMode={props.darkMode}
                    deleteFileFunction={deleteFile}
                  />
                </div>
              );
              let dataForSub2 = props.data[field.mappedListSubId1 ?? ""];
              if (dataForSub2) {
                let listMap2 = list[dataForSub2];
                if (listMap2) {
                  showThirdList = true;
                  thirdList = (
                    <div key={field.order + ":" + field.stitchTypeId + "2"}>
                      <GenericField2
                        value={props.data[field.mappedListSubId2 ?? ""]}
                        onChange={ocSub2}
                        title={field.mappedListSubTitle2}
                        fieldType={"dropdown_dynamic"}
                        description={field.description}
                        options={listMap2}
                        supportsOther={false}
                        disabled={false}
                        darkMode={props.darkMode}
                        deleteFileFunction={deleteFile}
                      />
                    </div>
                  );
                }
              }
            }
          }
          let firstList = [];
          for (let valueText in props.optionsLists[field.stitchTypeId]
            .class3ListMap) {
            firstList.push(valueText);
          }
          let f = (
            <div key={field.order + ":" + field.stitchTypeId}>
              <GenericField2
                value={props.data[field.stitchTypeId]}
                onChange={oc}
                title={field.title}
                fieldType={"dropdown_dynamic"}
                description={field.description}
                options={firstList}
                supportsOther={field.supportsOther}
                disabled={!field.userEditable}
                darkMode={props.darkMode}
                deleteFileFunction={deleteFile}
              />
            </div>
          );
          r.push(f);
          if (showSecondList) r.push(secondList);
          if (showThirdList) r.push(thirdList);
        } else if (field.type !== "multi") {
          //Prepare different onchanges
          let oc: any = (ev: string) => {
            ocBasic(ev, field.stitchTypeId);
          };
          if (field.type === "file")
            oc = (ev: React.FormEvent<HTMLInputElement>) => {
              ocFile(ev, field.stitchTypeId);
            };
          if (field.type === "multipleChoice")
            oc = (choice: string, checked: boolean, index: number) => {
              ocMulti(choice, checked, field.stitchTypeId, index);
            };
          if (field.type === "singleChoice") {
            oc = (choice: string) => {
              ocSingleChoice(choice, field.stitchTypeId);
            };
          }
          let f = (
            <div key={field.order + ":" + field.stitchTypeId}>
              <GenericField2
                value={props.data[field.stitchTypeId]}
                onChange={oc}
                title={field.title}
                fieldType={field.type}
                description={field.description}
                options={choices}
                supportsOther={field.supportsOther}
                disabled={!field.userEditable}
                darkMode={props.darkMode}
                deleteFileFunction={deleteFile}
              />
              <div style={{ height: "40" }}>
                {validation !== "" && (
                  <p className='txt-validation'>{validation}</p>
                )}
                {validation === "" && <div style={{ height: "100%" }}></div>}
              </div>
            </div>
          );
          r.push(f);
        } else {
          for (let i = 0; i < props.multiFields[field.stitchTypeId]; i++) {
            let subrows: JSX.Element[] = [];
            //Add Subrows
            for (let subk in field.subFields) {
              let subField = field.subFields[subk];
              if (subField.type === "size") continue;
              let subId2 = subField.stitchTypeId.replace(
                field.stitchTypeId,
                ""
              );
              let subId = `${field.stitchTypeId}[${i}]${subId2}`;
              let subVal = props.validations[subId]
                ? props.validations[subId]
                : "";
              let oc2: any = (ev: string) => {
                ocBasic(ev, subId);
              };
              if (subField.type === "file")
                oc2 = (ev: React.FormEvent<HTMLInputElement>) => {
                  ocFile(ev, subId);
                };
              if (subField.type === "multipleChoice")
                oc2 = (choice: string, checked: boolean, index: number) => {
                  ocMulti(choice, checked, subId, index);
                };
              if (subField.type === "singleChoice") {
                oc2 = (choice: string) => {
                  ocSingleChoice(choice, subId);
                };
              }
              let choices2 = subField.choices;
              if (subField.type === "dropdown_dynamic") {
                choices2 = props.optionsLists[subField.stitchTypeId].options;
              }
              let subFieldDraw = (
                <div key={subField.order + ":" + subField.stitchTypeId}>
                  <GenericField2
                    value={props.data[subId]}
                    onChange={oc2}
                    title={subField.title}
                    // key={subField.order + ":" + subField.stitchTypeId}
                    fieldType={subField.type}
                    description={subField.description}
                    options={choices2}
                    supportsOther={subField.supportsOther}
                    darkMode={props.darkMode}
                    deleteFileFunction={deleteFile}
                  />
                  <div style={{ height: "40" }}>
                    {subVal !== "" && (
                      <p className='txt-validation'>{subVal}</p>
                    )}
                    {subVal === "" && <div style={{ height: "100%" }}></div>}
                  </div>
                </div>
              );
              subrows.push(subFieldDraw);
            }
            //Order subrows
            subrows.sort((a, b) => {
              let ak = a.key ?? ":".split(":")[0];
              let bk = b.key ?? ":".split(":")[0];
              if (ak > bk) {
                return 1;
              } else {
                return -1;
              }
            });
            // console.log("sub array status");
            // for (let i = 0; i < subrows.length; i++) {
            // console.log(subrows[i].key);
            // }
            //Create main container

            let f2 = (
              <div
                className='col'
                style={{ marginTop: "50px" }}
                key={field.order + ":" + field.stitchTypeId + "__" + i}
              >
                {/* Title */}
                <div className='row'>
                  <h1 className='col txt-label'>
                    {field.title} {i + 1}
                  </h1>
                </div>
                {/* Sub Rows */}
                <div className='row'>
                  <div className='col'>{subrows}</div>
                </div>
                {/* Validacion */}
                <div style={{ height: "40" }}>
                  {validation !== "" && (
                    <p className='txt-validation'>{validation}</p>
                  )}
                  {validation === "" && <div style={{ height: "100%" }}></div>}
                </div>
                {/* Remove Item Button */}
                {i === props.multiFields[field.stitchTypeId] - 1 && i !== 0 && (
                  <div>
                    {/* Remove Item Button */}
                    <div className='row' style={{ marginTop: "50px" }}>
                      <ButtonStyleNoHeight
                        onClick={() => {
                          props.deleteMultiItem(field.stitchTypeId);
                        }}
                        text={"Eliminar " + field.title}
                        width='177px'
                      />
                    </div>
                  </div>
                )}
                {i === props.multiFields[field.stitchTypeId] - 1 && (
                  <div>
                    {/* Add Stuff Button */}
                    <div className='row' style={{ marginTop: "30px" }}>
                      <ButtonStyleNoHeight
                        onClick={() => {
                          props.addMultiItem(field.stitchTypeId);
                        }}
                        text={"Agregar " + field.title}
                        width='177px'
                      />
                    </div>
                  </div>
                )}
              </div>
            );
            r.push(f2);
          }
        }
      }
    }
    r.sort((a, b) => {
      let ak = a.key ?? ":".split(":")[0];
      let bk = b.key ?? ":".split(":")[0];
      if (ak > bk) {
        return 1;
      } else {
        return -1;
      }
    });
    // console.log("main array status");
    // for (let i = 0; i < r.length; i++) {
    //   console.log(r[i].key);
    //   console.log(r[i]);
    // }
    return r;
  }

  return <div>{getRows()}</div>;
};

interface MultiFieldProps {
  multiFieldId: string;
  fields: { [key: string]: FormField };
  onChange: (val: string, fieldId: string) => void;
}
const MultiField = (props: MultiFieldProps) => {};
